<style scoped>
/* Set the page size to A4 and the orientation to landscape */
@page {
  size: letter portrait;
}

/* Set the font size to 12px for printing */
@media print {
  body {
    font-size: 12px;
  }

  .page-break {
    page-break-after: always;
  }
}

#logo {
  position: absolute;
  top: 2px;
  right: 2px;
  width: 200px;
  height: 100px;
}

#title {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 1rem;
  color: #000000;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
}

.table-sku {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  border-bottom: 2px solid #000000;
}

.last-sku {
  border-bottom: 2px solid #000000;
}

#no-border {
  border: none;
}

th {
  border: 2px solid #000000;
  text-align: left;
  padding: 8px;
}

td {
  border: none;
  text-align: left;
  padding: 8px;
}

.remove_border tr th {
  border: none;
}

#table-list {
  margin-top: 80px;
}

#info {
  /* margin-top: 20px; */
  width: 40%;
  float: left;
}

.space-for-logo {
  width: 200px;
  height: 100px;
}
</style>

<template>
  <div class="border-2 border-solid border-black text-black">
    <div
      class="flex flex-col border-b-2 border-t-0 border-r-0 border-l-0 border-solid border-black pb-3"
    >
      <h3 class="font-bold text-center mb-3">NOTA RETUR</h3>
      <div id="info" class="w-full">
        <table class="remove_border w-full">
          <tr>
            <th class="py-0"></th>
            <th class="py-0"></th>
            <th class="py-0"></th>
            <th class="py-0"></th>
            <th class="py-0">Nomor</th>
            <th class="py-0">:</th>
            <th class="py-0">{{ data.debitNotes.Code }}</th>
          </tr>
          <tr>
            <th class="py-0">Atas Faktur Pajak Nomor</th>
            <th class="py-0">:</th>
            <th class="py-0">
              {{
                data.debitNotes.TaxInvoiceReferenceRefText
                  ? data.debitNotes.TaxInvoiceReferenceRefText
                  : data.taxInvoiceReference.TaxReturnNumber
              }}
            </th>
            <th class="py-0"></th>
            <th class="py-0">Tgl</th>
            <th class="py-0">:</th>
            <th class="py-0">
              {{ formatDate(data.debitNotes.TaxReturnDate) }}
            </th>
          </tr>
        </table>
      </div>
    </div>
    <div class="mb-3">
      <table class="remove_border">
        <tr>
          <th colspan="3">PEMBELI</th>
        </tr>
        <tr>
          <th class="py-0">Nama</th>
          <th class="py-0">:</th>
          <th class="py-0">{{ data.company.Name }}</th>
        </tr>
        <tr>
          <th class="py-0">Alamat</th>
          <th class="py-0">:</th>
          <th class="py-0">{{ data.company.Address }}</th>
        </tr>
        <tr>
          <th class="py-0">NPWP</th>
          <th class="py-0">:</th>
          <th class="py-0">{{ data.company.Npwp }}</th>
        </tr>
        <tr>
          <th colspan="3">KEPADA PENJUAL</th>
        </tr>
        <tr>
          <th class="py-0">Nama</th>
          <th class="py-0">:</th>
          <th class="py-0">{{ data.supplier.Name }}</th>
        </tr>
        <tr>
          <th class="py-0">Alamat</th>
          <th class="py-0">:</th>
          <th class="py-0">
            {{
              `${data.supplier.Address}, ${data.supplier.SubDistrict}, ${data.supplier.District}, ${data.supplier.City}, ${data.supplier.Province}`
            }}
          </th>
        </tr>
        <tr>
          <th class="py-0">NPWP</th>
          <th class="py-0">:</th>
          <th class="py-0">{{ data.supplier.Npwp }}</th>
        </tr>
      </table>
    </div>
    <table v-if="isQueryDone" class="table-sku" style="width: 100%">
      <thead>
        <tr>
          <th
            class="border-l-0"
            style="vertical-align: middle; text-align: center"
          >
            No. Urut
          </th>
          <th style="vertical-align: middle; text-align: center">
            Macam dan Jenis Barang Kena Pajak
          </th>
          <th style="vertical-align: middle; text-align: center">Kuantum</th>
          <th style="vertical-align: middle; text-align: center">
            Harga Satuan menurut Faktur Pajak
          </th>
          <th
            class="border-r-0"
            style="vertical-align: middle; text-align: center"
          >
            Harga BKP yang dikembalikan (Rp)
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(tr, idxtr) in data.lines" :key="idxtr">
          <td
            :class="idxtr === data.lines.length - 1 ? 'last-sku' : ''"
            style="vertical-align: middle; text-align: center"
          >
            {{ idxtr + 1 }}
          </td>
          <td
            :class="idxtr === data.lines.length - 1 ? 'last-sku' : ''"
            style="vertical-align: middle; text-align: start"
          >
            {{ tr.ItemName }}
          </td>
          <td
            :class="idxtr === data.lines.length - 1 ? 'last-sku' : ''"
            style="vertical-align: middle; text-align: center"
          >
            {{ tr.ReturnQuantity }} {{ tr.UnitName }}
          </td>
          <td
            :class="idxtr === data.lines.length - 1 ? 'last-sku' : ''"
            style="vertical-align: middle; text-align: right"
          >
            {{ formatNumber(getPriceDN(idxtr)) }}
          </td>
          <td
            :class="idxtr === data.lines.length - 1 ? 'last-sku' : ''"
            style="vertical-align: middle; text-align: right"
          >
            {{ formatNumber(tr.DNValue) }}
          </td>
        </tr>
        <tr>
          <td
            class="border-2 border-t-0 border-r-0 border-l-0 border-solid border-black"
            colspan="4"
          >
            Jumlah Harga BKP yang dikembalikan
          </td>
          <td
            class="border-2 border-t-0 border-r-0 border-solid border-black"
            style="text-align: right"
          >
            {{ formatNumber(data.debitNotes.DNValue) }}
          </td>
        </tr>
        <tr>
          <td colspan="4">Dikurangi Potongan Harga</td>
          <td
            class="border-2 border-t-0 border-r-0 border-b-0 border-solid border-black"
            style="text-align: right"
          >
            {{ 0 }}
          </td>
        </tr>
        <tr>
          <td colspan="4">Dasar Pengenaan Pajak</td>
          <td
            class="border-2 border-t-0 border-r-0 border-b-0 border-solid border-black"
            style="text-align: right"
          >
            {{ formatNumber(data.debitNotes.DNValue) }}
          </td>
        </tr>
        <tr>
          <td colspan="4">Pajak Pertambahan Nilai yang diminta kembali</td>
          <td
            class="border-2 border-t-0 border-r-0 border-b-0 border-solid border-black"
            style="text-align: right"
          >
            {{ formatNumber(data.debitNotes.TaxValue) }}
          </td>
        </tr>
        <tr>
          <td colspan="4">
            Pajak Penjualan Atas Barang Mewah yang diminta kembali
          </td>
          <td
            class="border-2 border-t-0 border-r-0 border-b-0 border-solid border-black"
          ></td>
        </tr>
      </tbody>
    </table>
    <div class="w-full flex mb-6">
      <div class="w-1/2"></div>
      <div class="w-1/3 text-center">
        <div>
          Jakarta Timur, {{ formatDate2(data.debitNotes.TaxReturnDate) }}
        </div>
        <div>Pembeli</div>
        <div class="mt-16">( Harsinto )</div>
        <div style="border-bottom: 1px solid black"></div>
        <div>Direktur</div>
      </div>
      <div class="w-1/6"></div>
    </div>
    <div
      class="flex flex-col pt-1 gap-1 border-2 border-l-0 border-r-0 border-b-0 border-black border-solid"
    >
      <div class="ml-3">
        Lembar ke-1 untuk Pengusaha Kena Pajak yang menerbitkan Faktur Pajak
      </div>
      <div class="ml-3">Lembar ke-2 untuk Pembeli</div>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";
// import logoSDN from "../../../assets/images/logo/logo-sdn.png";
export default {
  name: "PrintPdf",
  // props: {
  //     handover_id: Number
  // },
  data() {
    return {
      id: null,
      handover_ids: [],
      table: [],
      sto_plan_code: null,
      picking_code: null,
      selectedSrcWarehouse: null,
      selectedDestWarehouse: null,
      reqDeliveryDate: null,
      eta: null,
      // logoSDN: logoSDN,
      showInfo: false,
      data: {
        company: {},
        debitNotes: {},
        lines: [],
      },
      supplierReturnDetails: [],
      isQueryDone: false,
    };
  },
  mounted() {
    this.print();
  },
  methods: {
    async print() {
      await this.getDebitNote();
      await this.getSupplierReturnDetails();
      document.title = "Nota Retur_" + this.data.debitNotes.Code;
      this.isQueryDone = true;
      this.$nextTick(async () => {
        await window.print();
      });
    },
    async getData() {
      await this.getDebitNote();
      await this.getSupplierReturnDetails();
    },
    getPrice(id) {
      return this.supplierReturnDetails.find((item) => item.id === id).price;
    },
    getPriceDN(i) {
      const dnValue = this.data.lines[i].DNValue;
      const qty = this.data.lines[i].DNQuantity;

      return dnValue / qty;
    },
    async getDebitNote() {
      this.$vs.loading();
      // let resp = await this.$http.get(`/api/wms/v1/simple-outbound-planner/handover-data/${this.handover_id}`);
      let resp = await this.$http.get(`/api/v1/debit-note/return/detail`, {
        params: {
          id: this.$route.params.id,
        },
      });
      if (resp.code == 200) {
        this.data = resp.data;
        this.$vs.loading.close();
      } else {
        this.$vs.loading.close();
      }
    },
    async getSupplierReturnDetails() {
      this.$vs.loading();
      let pr_ids = [];
      let sr_ids = [];
      this.data.lines.forEach((line) => {
        if (line.SupplierReturnID) {
          sr_ids.push(line.SupplierReturnID);
        } else if (line.PurchaseReturnID) {
          pr_ids.push(line.PurchaseReturnID);
        }
      });

      pr_ids = [...new Set(pr_ids)];
      sr_ids = [...new Set(sr_ids)];
      const params = {
        pr_ids,
        sr_ids,
        is_edit: true,
      };
      try {
        const response = await this.$http.get(
          "/api/v1/debit-note/supplier-return/detail",
          {
            params,
          }
        );
        this.supplierReturnDetails = response.data;
        console.log("supl", this.supplierReturnDetails);
        this.$vs.loading.close();
      } catch (e) {
        this.$vs.loading.close();
        throw e;
      }
    },
  },

  computed: {
    formatDate: () => {
      return (val) => {
        const momentDate = moment.utc(val);
        // momentDate.add(1, 'days');              // Tambahkan 1 hari
        return momentDate.format("DD.MM.YYYY");
      };
    },
    formatDate2: () => {
      return (val) => {
        const momentDate = moment.utc(val);
        // momentDate.add(1, 'days');              // Tambahkan 1 hari
        return momentDate.format("DD MMMM YYYY");
      };
    },
    formatNumber: () => {
      return (val) => {
        return Intl.NumberFormat("en", { minimumFractionDigits: 2 }).format(
          val
        );
      };
    },
  },
};
</script>
